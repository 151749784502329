<template>
  <el-dialog
      v-model="isShow"
      title="选择安装日期"
      width="600px"
      :before-close="handleClose"
  >
    <el-calendar :range="selectTime">
      <template #date-cell="{data}">
        <div style="margin:0px">
          {{ data.day.split('-').slice(2).join() }}
          <div v-for="(item, index) in dayTeamIncompleteTaskStatistic" :key="index" style="text-align: center">
            <div v-if="data.day==item.date" style="font-size: 40px;color: #FB9A35"> {{ item.count }}</div>
          </div>
        </div>
      </template>
    </el-calendar>
    <el-form ref="formRef" :inline="true" :rules="rules" :model="formState">
      <el-row>
        <el-col :span="24">
          <el-form-item prop="installTime" label="安装时间">
            <el-date-picker
                v-model="formState.installTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                value-format="YYYY-MM-DD"
                @change="installTimeChange"
            />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
        <span class="dialog-footer">
          <el-button @click="handleClose">取消</el-button>
          <el-button type="primary" @click="handleSubmit">
            确定
          </el-button>
        </span>
    </template>
  </el-dialog>
</template>

<script>
import {isResOK} from "@/api/response";
import {selectDayTeamIncompleteTaskStatistic} from "@/api/installation_plan";
import dayjs from "dayjs";

export default {
  name: "SelectDate",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    teamId: {
      type: String,
      required: true,
    },
    beginInstallTime: {
      type: String,
      required: true,
    },
    endInstallTime: {
      type: String,
      required: true,
    },
  },

  emits: ['update:visible', 'completed'],
  data() {
    return {
      selectTime: [new Date(dayjs().year(), dayjs().month(), dayjs().day()), new Date(dayjs().year(), dayjs().subtract(1,'month').month(), dayjs().subtract(1,'month').day())],
      formState: {
      },
      // 表单校验
      rules: {
        installTime: [
          { required: true, message: "安装时间不能为空", trigger: "blur" },
        ],
      },
      dayTeamIncompleteTaskStatistic: [],
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },

      set(val) {
        this.$emit('update:visible', val)
      },
    },
  },
  created() {
    this.fetchDayTeamIncompleteTaskStatistic()
    if (this.beginInstallTime !== null && this.beginInstallTime !== '') {
      this.formState.installTime = [this.beginInstallTime, this.endInstallTime]
      this.formState.beginInstallTime = this.beginInstallTime
      this.formState.endInstallTime = this.endInstallTime
    }
    this.selectTime = [this.beginInstallTime, this.endInstallTime]
  },
  methods: {
    installTimeChange(val) {
      if (val !== null && val.length == 2) {
        this.formState.beginInstallTime = val[0];
        this.formState.endInstallTime = val[1];
        this.selectTime = val
      } else {
        this.selectTime = [new Date(dayjs().year(), dayjs().month(), dayjs().day()), new Date(dayjs().year(), dayjs().subtract(1,'month').month(), dayjs().subtract(1,'month').day())]
      }
    },

    handleClose() {
      this.isShow = false
    },

    handleSubmit() {
      this.$refs["formRef"].validate((valid) => {
        if (valid) {
          this.$emit('completed', this.formState)
          this.isShow = false
        }
      })
    },

    fetchDayTeamIncompleteTaskStatistic() {
      selectDayTeamIncompleteTaskStatistic({ teamId: this.teamId }).then(res => {
        if (isResOK(res)) {
          this.dayTeamIncompleteTaskStatistic = res.data
        }
      })
    }

  }
}
</script>

<style scoped>

</style>
